import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { postRegister, setToastMessage } from '../../js/redux/actions';
import { acceptOnlyNumbersInput, clearFormFieldErrors, isValidPhoneNumber } from '../../js/utils';
import Button from '../common/Button';
import PhoneNumberInput from '../common/PhoneNumberInput';
import TextInput from '../common/TextInput'
import ErrorText from '../common/ErrorText';
import Loader from '../common/Loader';

export default function Register() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const initialFormData = {
        countryCode: '+91',
        phoneNo: '',
        name: '',
        password: ''
    };

    const spamData = {
        type: location?.state?.type,
        uniqueId: location?.state?.uniqueId
    };

    const [formData, setFormData] = useState(initialFormData);
    const [errors, setErrors] = useState({});
    const [btnDisable, setBtnDisable] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handlePhoneNoChange = value => {
        if (value === '' || acceptOnlyNumbersInput(value)) {
            setErrors(clearFormFieldErrors('phoneNo', errors));
            setFormData({
                ...formData,
                phoneNo: value
            });
        }
    }
    const handleChange = (key, value) => {
        setErrors(clearFormFieldErrors(key, errors));
        setFormData({
            ...formData,
            [key]: value
        });
    }

    const validateFormData = () => {
        let hasError = false;
        const err = Object.assign({}, errors);
        if (!isValidPhoneNumber(formData.phoneNo)) {
            hasError = true;
            err.phoneNo = 'Enter a valid phone number';
        }
        Object.keys(formData).forEach((key) => {
            if (typeof (formData[key]) === 'string' && key !== 'password' && key !== 'phoneNo' && key !== 'countryCode') {
                if (!formData[key].match(/\w/)) {
                    hasError = true;
                    err[key] = 'This field is required';
                }
            }
            if (!formData[key]) {
                hasError = true;
                err[key] = 'This field is required';
            }
        });
        if (hasError) {
            setErrors(err);
            return false;
        }
        return formData;
    };

    const handleSubmit = e => {
        e.preventDefault();
        const isValid = validateFormData();
        if (isValid) {
            setBtnDisable(true);
            setIsLoading(true);
            dispatch(postRegister(isValid)).then(resp => {
                setIsLoading(false);
                if (resp.data.success) {
                    dispatch(
                        setToastMessage({
                            status: "success",
                            message: resp?.data?.message,
                        })
                    );
                    navigate(
                        `/auth/verify-otp`,
                        {
                            state: {
                                countryCode: isValid.countryCode,
                                phoneNo: isValid.phoneNo,
                                type: spamData.type,
                                uniqueId: spamData.uniqueId
                            }
                        });
                }
                else {
                    setTimeout(() => {
                        setBtnDisable(false);
                    }, 3500);
                    dispatch(
                        setToastMessage({
                            status: "error",
                            message: resp?.data?.message,
                        })
                    );
                }
            })
        }
    }

    const handleLogin = () => {
        navigate(
            `/auth/login`,
            {
                state: {
                    type: spamData.type,
                    uniqueId: spamData.uniqueId
                }
            });
    }

    return (
        <div className="fab-register-container" id="fab-register-container">
            {isLoading && <Loader parentNode="fab-register-container"/>}
            <div className="fab-ac-top-text">
                Register
            </div>
            <div className="fab-form-control">
                <div className='fab-form-label'>Enter Phone Number</div>
                <PhoneNumberInput
                    placeholder="01234 56789"
                    value={formData.phoneNo}
                    name="phoneNo"
                    onChange={e => handlePhoneNoChange(e.target.value)}
                />
                <ErrorText error={errors.phoneNo} errorClass="w3-center" />
            </div>
            <div className="fab-form-control">
                <div className='fab-form-label'>Enter Your Name</div>
                <TextInput
                    type='text'
                    placeholder='what should we call you?'
                    value={formData.name}
                    onChange={(val) => handleChange('name', val)}
                />
                <ErrorText error={errors.name} />
            </div>
            <div className="fab-form-control">
                <div className='fab-form-label'>Enter Password</div>
                <TextInput
                    type='password'
                    placeholder='add a unique password'
                    value={formData.password}
                    onChange={(val) => handleChange('password', val)}
                />
                <ErrorText error={errors.password} />
            </div>
            <Button
                type="submit"
                onClick={(e) => handleSubmit(e)}
                buttontext='Send OTP'
                disabled={btnDisable}
                customclass={`primary-btn ${btnDisable ? 'fab-btn-disabled' : ''}`}
                style={{marginBottom:20}}
            />
            <div className="fab-register-info-text">
                <div>Already have an Account?
                    &nbsp;
                    <span className="fab-nav-link primary-link"
                        style={{ textDecoration: 'underline' }} onClick={e => handleLogin(e)}>
                        Login
                    </span>
                </div>
            </div>
        </div>
    )
}
