import React, { useEffect } from 'react';
import './modal.scss';

const Modal = (props) => {

    const { contentStyle, contentClass, children, modalClass,
        openClass, modalStyle, showCloseBtn, handleOverlayClick, handleClose, hideOverLay, header } = props;

    useEffect(() => {
        if (openClass === 'w3-show') {
            document.documentElement.className = 'fab-no-scroll';
        } else {
            document.documentElement.classList.remove("fab-no-scroll");
        }
    }, [openClass]);

    const handleOnClick = (e) => {
        e.preventDefault();
        handleOverlayClick && handleOverlayClick();
    }

    const handleModalClose = (e) => {
        e.preventDefault();
        if (handleClose) {
            handleClose();
        } else {
            handleOnClick(e);
        }
    }

    return (
        <div className={`w3-modal ${openClass} ${modalClass || ''}`}
            style={modalStyle || {}}>
            {!hideOverLay && <div
                onClick={e => handleOnClick(e)}
                className="fab-modal-overlay fab-cursor-pointer"
            ></div>}
            <div
                className={`w3-modal-content ${contentClass || ''}`}
                style={contentStyle ? Object.assign({}, contentStyle, { position: 'relative' }) : {}}
            >
                <div className="fab-modal-header-container">
                {header && <div>
                    {header}
                </div>}
                {showCloseBtn && (
                    <div className="fab-modal-cross"
                        onClick={e => handleModalClose(e)}
                    />
                )}
                </div>
                {children}
            </div>
        </div>
    );
}

export default Modal