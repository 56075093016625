import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { acceptOnlyNumbersInput, clearFormFieldErrors, isValidPhoneNumber } from '../../js/utils';
import Button from '../common/Button';
import ErrorText from '../common/ErrorText';
import PhoneNumberInput from '../common/PhoneNumberInput';
import { postForgotPassword, setToastMessage } from '../../js/redux/actions';
import Loader from '../common/Loader';

export default function Forgot() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const initialFormData = {
        countryCode: '+91',
        phoneNo: '',
    };
    const [formData, setFormData] = useState(initialFormData);
    const [errors, setErrors] = useState({});
    const [btnDisable, setBtnDisable] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handlePhoneNoChange = value => {
        if (value === '' || acceptOnlyNumbersInput(value)) {
            setErrors(clearFormFieldErrors('phoneNo', errors));
            setFormData({
                ...formData,
                phoneNo: value
            });
        }
    }

    const validateFormData = () => {
        let hasError = false;
        const err = Object.assign({}, errors);
        if (!isValidPhoneNumber(formData.phoneNo)) {
            hasError = true;
            err['phoneNo'] = 'Enter a valid phone number';
        }
        if (!formData['phoneNo']) {
            hasError = true;
            err['phoneNo'] = 'This field is required';
        }
        if (hasError) {
            setErrors(err);
            return false;
        }
        return formData;
    };

    const handleSubmit = e => {
        e.preventDefault();
        const isValid = validateFormData();
        if (isValid) {
            setBtnDisable(true);
            setIsLoading(true);
            dispatch(postForgotPassword(isValid)).then(resp => {
                setIsLoading(false);
                if (resp.data.success) {
                    dispatch(
                        setToastMessage({
                            status: "success",
                            message: resp?.data?.message,
                        })
                    );
                    navigate(
                        `/auth/reset-password`,
                        {
                            state: {
                                countryCode: isValid.countryCode,
                                phoneNo: isValid.phoneNo
                            }
                        });
                }
                else {
                    setTimeout(() => {
                        setBtnDisable(false);
                    }, 3500);
                    dispatch(
                        setToastMessage({
                            status: "error",
                            message: resp?.data?.message,
                        })
                    );
                }
            })
        }
    }

    return (
        <div className="fab-forgot-pass-container" id="fab-forgot-pass-container">
            {isLoading && <Loader parentNode="fab-forgot-pass-container" />}
            <div className="fab-ac-top-text">
                Reset Password
            </div>
            <div className="fab-form-control">
                <div className='fab-form-label'>Enter Phone Number</div>
                <PhoneNumberInput
                    placeholder="01234 56789"
                    value={formData.phoneNo}
                    name="phoneNo"
                    onChange={e => handlePhoneNoChange(e.target.value)}
                />
                <ErrorText error={errors.phoneNo} errorClass="w3-center" />
            </div>
            <Button
                onClick={e => handleSubmit(e)}
                buttontext='Send OTP'
                disabled={btnDisable}
                customclass={`primary-btn ${btnDisable ? 'fab-btn-disabled' : ''}`}
                style={{marginBottom:20}}
            />
            <Button
                onClick={() => {
                    navigate(`/auth/login`)
                }}
                buttontext='Back to Login'
                customclass="fab-btn-link"
                style={{textDecoration:'underline'}}
            />
        </div>
    )
}
