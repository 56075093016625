/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import ScamyodhaLogo from '../../../images/icons/scamyodha_app_text_logo.svg';
import { CONTACT_MAIL, SOCIAL_LINKS } from '../../../js/constants';
import './footer.scss';
import twitterLogo from '../../../images/icons/twitter_icon.svg';
import fbLogo from '../../../images/icons/facebook_icon.svg';
import linkedInLogo from '../../../images/icons/linkedin_icon.svg';
import discordLogo from '../../../images/icons/discord_blue.svg';
import playStore from '../../../images/icons/Play.svg';
import appStore from '../../../images/icons/apple.svg';

const Footer = () => {
    return (
        <div className="fab-footer-container" id="app-footer">
            <div className="fab-footer-top-section">
                <div className="fab-footer-top-section-left">
                    <div className="fab-footer-fab-logo">
                        <img src={ScamyodhaLogo} className="fab-logo-img s4 w3-center" alt="footer-fab-logo" />
                    </div>
                </div>
                <div className="fab-footer-top-section-right">
                    <Link to='/about' className="fab-nav-link">About</Link>
                    <a href={`mailto:${CONTACT_MAIL}`} className="fab-nav-link">Contact Us</a>
                    <Link to='/privacy-policy' className="fab-nav-link">Privacy Policy</Link>
                    <Link to='/terms-of-use' className="fab-nav-link">Terms of Use</Link>
                </div>
            </div>
            <div className='fab-app-download-container'>
                <a className="fab-nav-link"  href={SOCIAL_LINKS.playStore} target="_blank" rel="noreferrer">
                    <img src={playStore} alt="playstore" />
                </a>
                {/* <a className="fab-nav-link"  href="#" target="_blank" rel="noreferrer"> */}
                    <span className='w3-display-container'>
                        <img src={appStore} alt="appstore" />
                        <p className='w3-display-topmiddle'>Coming Soon!</p>
                    </span>
                {/* </a> */}
            </div>
            <div className="fab-footer-social-container">
                <a className="fab-footer-social-btn fab-nav-link" href={SOCIAL_LINKS.discordChannel} target="_blank" rel="noreferrer">
                    <img src={discordLogo} alt="discord-logo" style={{height:40}}/>
                </a>
                <a className="fab-footer-social-btn fab-nav-link" href={SOCIAL_LINKS.facebook} target="_blank" rel="noreferrer">
                    <img src={fbLogo} alt="facebook-logo" />
                </a>
                <a className="fab-footer-social-btn fab-nav-link" href={SOCIAL_LINKS.linkedIn} target="_blank" rel='noreferrer'>
                    <img src={linkedInLogo} alt="linkedin-logo" />
                </a>
                <a className="fab-footer-social-btn fab-nav-link" href={SOCIAL_LINKS.twitter} target="_blank" rel="noreferrer">
                    <img src={twitterLogo} alt="twitter-logo" />
                </a>
            </div>
            <div className="fab-footer-rights-section">
                <div className="fab-footer-rights-made-in-text">
                    Made 🔐 in India
                </div>
                <div className="fab-footer-rights-copy-text">
                    Copyright @ 2022 <a href='https://www.ceegees.in' className="fab-nav-link" target="_blank" rel="noreferrer">Ceegees Pvt Ltd</a>. All Right Reserved
                </div>
            </div>
        </div>
    )
}

export default Footer