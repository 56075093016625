import React, { useEffect, useState } from 'react';
import './auth.scss';
import AuthCard from './AuthCard';
import { useLocation } from 'react-router-dom';
import Login from './Login';
import VerifyOtp from './VerifyOtp';
import Register from './Register';
import Forgot from './Forgot';
import Layout from '../Layout';

export default function AuthLayout() {
    const location = useLocation();
    const [view, setView] = useState(null);
    let viewContent = null;

    useEffect(() => {
        const currentPath = location?.pathname.split('/').pop();
        setView(currentPath);
        return ()=>{
            setView(null);
        }
    }, [location]);

    if (view === 'register') {
        viewContent = <Register />
    } else if (view === 'login') {
        viewContent = <Login />
    } else if (view === 'forgot-password') {
        viewContent = <Forgot />
    } else if (view === 'reset-password') {
        viewContent = <VerifyOtp type='reset-passord' />
    } else if (view === 'verify-otp') {
        viewContent = <VerifyOtp />
    }
    return (
        <Layout>
            <AuthCard>
                {viewContent}
            </AuthCard>
        </Layout>

    )
}
