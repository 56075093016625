import { createContext, useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { getCurrentUser, getIsTokenValid } from '../js/redux/actions';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const initialState = {
        isAuthenticated: false,
        accessToken: null,
    }
    const dispatch = useDispatch();
    const [auth, setAuthState] = useState(initialState);
    const [userData, setUserData] = useState(null);

    const getUserData = () => {
        dispatch(getCurrentUser()).then(resp => {
            const respData = resp && resp.data;
            if (respData?.success && respData?.data?.user) {
                setUserData(respData?.data?.user);
            }
        })
    }

    const isTokenValid = () => {
        const accessToken = localStorage.getItem('fab_access_token') || '';
        if(accessToken){
            dispatch(getIsTokenValid(accessToken)).then(resp=>{
                const respData = resp && resp.data;
                if (respData?.success) {
                    setUserData(respData?.userData);
                    setAuthState({
                        accessToken,
                        isAuthenticated: true
                    });
                } else if(!respData?.success){
                    clearData();
                }
            });
        }else{
            // clearData();
        }
    }

    const clearData = () =>{
        setAuthState(initialState);
        setUserData(null);
        setAuthData();
    }


    const setAuthData = token => {
        let isAuthenticated = false;
        let accessToken = '';
        if (token) {
            accessToken = token;
            isAuthenticated = true;
        }
        localStorage.setItem('fab_access_token', accessToken);
        setAuthState({
            accessToken,
            isAuthenticated
        });
    };

    useEffect(()=>{
        if(!auth.isAuthenticated || !auth.accessToken){
            return;
        }
        getUserData();
        return () =>{
            // setUserData(null);
        }
    },[auth])

    useEffect(() => {
        isTokenValid();
        return ()=>{
            // setAuthState(initialState);
            // setUserData(null);
        }
    }, []);
  
    return (
        <AuthContext.Provider value={{ auth, setAuthData, userData, getUserData, isTokenValid }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;