import React, { forwardRef } from 'react';
import './box-input.scss';

const BACK_SPACE = 8;
const DELETE = 46;


const SingleInput = forwardRef((props, ref) => {
    const { onChange, onBackSpace, disabled, secureEntry, showBoxSeparator, boxSeparator, isLastBox,inputBoxClass } = props;

    const handleKeyUp = (e) => {
        if (e.keyCode === BACK_SPACE || e.keyCode === DELETE) {
            onBackSpace();
        } else {
            onChange(e);
        }
    };

    return (
        <>
            <input
                type={!secureEntry ? "text" : "password"}
                disabled={disabled}
                className={`single-input ${inputBoxClass}`}
                maxLength={1}
                onChange={onChange}
                ref={ref}
                onKeyUp={handleKeyUp}
            />
            {showBoxSeparator && !isLastBox && (
                <span className="box-separator">{boxSeparator}</span>
            )}
        </>
    );
});

export default SingleInput;