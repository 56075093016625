import { useEffect, useState } from 'react';

const useCountDown = (maxCount) => {
    const [countDown, setCountDown] = useState(maxCount);

    useEffect(() => {
        const interval = setInterval(() => {
            setCountDown(countDown - 1);
        }, 1000);

        return () => clearInterval(interval);
    }, [countDown]);

    return [countDown];
};
export { useCountDown };
