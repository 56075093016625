import React, { useState, useEffect } from "react";
import "./main-card.scss";
import Button from "../Button";
import PhoneNumberInput from "../PhoneNumberInput";
import TextInput from "../TextInput";
import { acceptOnlyNumbersInput, clearFormFieldErrors, isValidPhoneNumber, isValidUpiId } from '../../../js/utils';
import { checkScamReport } from "../../../js/redux/actions";
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import ErrorText from "../ErrorText";
import BackButtonIcon from '../../../images/icons/arrow_left.svg';
import moment from 'moment';
const MainCard = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialFormData = {
    countryCode: "+91",
    phoneNo: "",
    upi: "",
    sms: "",
    website: ""
  };
  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});
  const [type, setType] = useState("");

  const [viewType, setViewType] = useState("number");
  const [spamData, setSpamData] = useState({});
  const handleChange = (key, value) => {
    setFormData({
      ...formData,
      [key]: value,
    });
    setErrors({
      ...errors,
      [key]: '',
    });
  };

  const validateSaveData = () => {
    let hasError = false;
    const err = Object.assign({}, errors);
    if (viewType === 'number') {
      if (!isValidPhoneNumber(formData.phoneNo)) {
        hasError = true;
        err.phoneNo = 'Enter a valid phone number';
      }
      if (!formData.phoneNo) {
        hasError = true;
        err.phoneNo = 'This field is required';
      }
    }
    else if(viewType === 'upi') {
      if (!formData.upi) {
        hasError = true;
        err.upi = 'This field is required';
      }
      else if (!isValidUpiId(formData.upi)) {
        hasError = true;
        err.upi = 'Enter a valid upi';
      }
    } else if(viewType === 'sms') {
      if (!formData.sms) {
        hasError = true;
        err.sms = 'This field is required';
      }
    }
    else if(viewType === 'website') {
      if (!formData.website) {
        hasError = true;
        err.website = 'This field is required';
      }
    }

    if (hasError) {
      setErrors(err);
      return false;
    }
    return true;
  };

  const handleScamSearch = (e, type) => {
    e.preventDefault();
    const isValid = validateSaveData();
    if (isValid) {
      setViewType(type)
      const postData = { type };
      if (type === 'number') {
        postData['countryCode'] = formData.countryCode;
        postData['phoneNo'] = formData.phoneNo;
      } else if (type === 'upi') {
        postData['upi'] = formData.upi;
      } else if (type === 'sms') {
        postData['sms'] = formData.sms;
      } else if (type === 'website') {
        postData['website'] = formData.website;
      }

      dispatch(checkScamReport(postData)).then(resp => {
        if (resp?.data?.success) {
          setType("spam")
          let data = resp.data.data
          data.lastReportedAt = moment(data.lastReportedAt).format('DD-MM-YYYY')
          setSpamData(data)
        }
        else {
          setType("notSpam")
        }
      });
    }
  }

  const handleReport = (e) => {
    e.preventDefault();
    let uniqueId = null;
      if(viewType === 'number'){
        uniqueId = formData.phoneNo;
      } else if(viewType === 'upi'){
        uniqueId = formData.upi;
      } else if(viewType === 'sms'){
        uniqueId = formData.sms;
      } else if(viewType === 'website'){
        uniqueId = formData.website;
      }

    if (localStorage.getItem('fab_access_token')) {
      navigate(
        `/report-spam`,
        {
          state: {
            type: viewType,
            uniqueId
          }
        });
    }
    else {
      navigate(
        `/auth/login`,
        {
          state: {
            type: viewType,
            uniqueId
          }
        }
      );
    }

  }

  const handlePhoneNoChange = value => {
    if (value === '' || acceptOnlyNumbersInput(value)) {
      setErrors(clearFormFieldErrors('phoneNo', errors));
      setFormData({
        ...formData,
        phoneNo: value
      });
    }
  }

  const handleTabClick = tabKey => {
    setViewType(tabKey);
    setFormData(initialFormData);
  }

  const renderTabs = () => {
    return (
      <div className="w3-bar fab-mc-btn-group">
        <div className={`w3-bar-item fab-cursor-pointer fab-mc-btn-grp-btn-text ${viewType === 'number' && 'fab-mc-btn-grp-active-btn'}`} onClick={() => handleTabClick('number')}>Phone</div>
        <div className={`w3-bar-item fab-cursor-pointer fab-mc-btn-grp-btn-text ${viewType === 'upi' && 'fab-mc-btn-grp-active-btn'}`} onClick={() => handleTabClick('upi')}>UPI</div>
        <div className={`w3-bar-item fab-cursor-pointer fab-mc-btn-grp-btn-text ${viewType === 'sms' && 'fab-mc-btn-grp-active-btn'}`} onClick={() => handleTabClick('sms')}>SMS</div>
        <div className={`w3-bar-item fab-cursor-pointer fab-mc-btn-grp-btn-text ${viewType === 'website' && 'fab-mc-btn-grp-active-btn'}`} onClick={() => handleTabClick('website')}>Website</div>
      </div>
    )
  }

  const renderNotSpamView = data => {
    const { title, name, placeholder, value, disabled, buttonText } = data;
    return (
      <div className="fab-main-card-container">
        <div className="fab-main-card">
          <div className="fab-mc-top-not-spam-msg">
            {title}
          </div>
          {viewType === 'number' ? (<div className="fab-mc-phone-input-container">
            <PhoneNumberInput
              placeholder={placeholder}
              value={value}
              name={name}
              disabled={disabled}
            />
          </div>) : (<TextInput
            placeholder={placeholder}
            value={value}
            name={name}
            disabled={disabled}
            inputclass="w3-margin-bottom"
          />)}
          <div className="fab-mc-btn-section">
            <Button
              onClick={() => {
                setType("")
                setFormData(initialFormData)
              }}
              buttontext={buttonText}
              customclass="primary-btn"
            />
          </div>
          <div className="fab-mc-spam-report" style={{ color: "black", marginTop: 20 }}>
            <p>
              If you think this is spam.{" "}
              <span
                className="fab-mc-spam-click-here "
                style={{ color: "#E5281C" }}
                onClick={e => handleReport(e)}>
                Click here!
              </span>
            </p>
          </div>

        </div>
        <div className="fab-mc-not-spam-bg" />
      </div>
    )
  }

  const renderView = () => {
    let viewContent = null;
    if (type === "spam") {
      viewContent = (
        <div className="fab-main-spam-card-container fab-spam-bg">
          <div className="fab-main-card">
            <div className="fab-mc-top-section">
              <img src={BackButtonIcon} alt="back-btn" className="fab-cursor-pointer" onClick={() => {
                setType("")
                setFormData(initialFormData)
              }} />
              <div className="fab-mc-top-spam-msg">Identified as spam</div>
            </div>
            {viewType === "number" &&
              (
                <div className="fab-mc-phone-input-container">
                  <PhoneNumberInput
                    placeholder="01234 56789"
                    value={formData.phoneNo}
                    name="phoneNo"
                    disabled
                  />
                </div>
              )}
            {viewType === "upi" && (
              <TextInput
                inputclass="fab-upi-input-text"
                placeholder="1234567890@upi"
                value={formData.upi}
                name="upi"
                disabled
              />
            )}
            {viewType === "sms" && (
              <TextInput
                inputclass="fab-upi-input-text"
                placeholder="Enter the SMS (ex.http://lnk.su/y2be9ebn)"
                value={formData.sms}
                name="sms"
                disabled
              />
            )}
            {viewType === "website" && (
              <TextInput
                inputclass="fab-upi-input-text"
                placeholder="Enter the URL (ex.http://scammer.url.com)"
                value={formData.website}
                name="website"
                disabled
              />
            )}

            <div className="fab-mc-btn-section">
              <Button
                buttontext="Report Spam"
                customclass="fab-mc-report-spam-btn"
                onClick={e => handleReport(e)}
              />
              <Button
                buttontext="View Report"
                customclass="fab-btn-link fab-mc-spam-click-here"
                style={{ color: '#FFFFFF', paddingTop: 20 }}
                onClick={event => navigate(`/reports/detail/${spamData.id}`, { state: { spamData } })}
              />
            </div>
            <div className="fab-mc-spam-report">
              <div className="fab-mc-report-row" style={{ marginBottom: 10 }}>
                <div>{spamData.reportCount} SPAM REPORTS</div>
                <div className="fab-mc-spam-dot">.</div>
                <div>TRAI CONFIRMS</div>
              </div>
              <div className="fab-mc-report-row">
                <div className="fab-mc-spam-sub-report" style={{ marginRight: "auto" }}>
                  Last Reported on {spamData.lastReportedAt}
                </div>
                <button className="fab-mc-spam-active">ACTIVE</button>
              </div>
              {/* <div className="fab-mc-report-row" style={{ paddingTop: "8px" }}>
                <div>If you think this is not spam.
                  <span className="fab-mc-spam-click-here">
                    Click here!
                  </span>
                </div>
              </div> */}
            </div>
          </div>
          <div className="fab-ac-spam-bg-container">
            <div className="fab-mc-spam-bg" />
          </div>
        </div>
      );
    } else if (type === "notSpam") {
      let data = {};
      if (viewType === 'number') {
        data = {
          title: 'Phone Number is not reported as spam',
          placeholder: "01234 56789",
          value: formData.phoneNo,
          name: "phoneNo",
          disabled: true,
          buttonText: "Try another phone"
        }
      }
      else if (viewType === 'upi') {
        data = {
          title: 'UPI is not reported as spam',
          placeholder: "1234567890@upi",
          value: formData.upi,
          name: "upi",
          disabled: true,
          buttonText: "Try another UPI"
        }
      }
      else if (viewType === 'sms') {
        data = {
          title: 'SMS is not reported as spam',
          placeholder: "Enter the SMS (ex.http://lnk.su/y2be9ebn)",
          value: formData.sms,
          name: "sms",
          disabled: true,
          buttonText: "Try another SMS"
        }
      }
      else if (viewType === 'website') {
        data = {
          title: 'Website is not reported as spam',
          placeholder: "Enter the URL (ex.http://scammer.url.com)",
          value: formData.website,
          name: "website",
          disabled: true,
          buttonText: "Try another Website"
        }
      }
      viewContent = renderNotSpamView(data);
    } else if (viewType === "number") {
      viewContent = (
        <div className="fab-main-card-container">
          <div className="fab-main-card">
            {renderTabs()}
            <div className="fab-mc-top-text">Search Phone number here</div>
            <PhoneNumberInput
              placeholder="Enter phone number"
              value={formData.phoneNo}
              name="phoneNo"
              onChange={e => handlePhoneNoChange(e.target.value)}
            />
            <ErrorText error={errors.phoneNo} errorClass="w3-center" />
            <br />
            <div className="fab-mc-btn-section">
              <Button
                onClick={e => handleScamSearch(e, "number")}
                buttontext="Check for scam"
                customclass="primary-btn"
              />
            </div>
          </div>
          <div className="fab-mc-default-bg" />
        </div>
      );
    }
    else if (viewType === "upi") {
      viewContent = (
        <div className="fab-main-card-container">
          <div className="fab-main-card">
            {renderTabs()}
            <div className="fab-mc-top-text">Search UPI here</div>
            <TextInput
              placeholder="Enter UPI id (ex.1234567890@upi)"
              value={formData.upi}
              name="upi"
              onChange={(val) => handleChange('upi', val)}
            />
            <ErrorText error={errors.upi} errorClass="w3-center" />
            <br />
            <div className="fab-mc-btn-section">
              <Button
                onClick={e => handleScamSearch(e, "upi")}
                buttontext="Check for scam"
                customclass="primary-btn"
              />
            </div>
          </div>
          <div className="fab-mc-default-bg" />
        </div>
      );
    }
    else if (viewType === "sms") {
      viewContent = (
        <div className="fab-main-card-container">
          <div className="fab-main-card">
            {renderTabs()}
            <div className="fab-mc-top-text">Search SMS link here</div>
            <TextInput
              placeholder="Enter the SMS (ex.http://lnk.su/y2be9ebn)"
              value={formData.sms}
              name="sms"
              onChange={(val) => handleChange('sms', val)}
            />
            <ErrorText error={errors.sms} errorClass="w3-center" />
            <br />
            <div className="fab-mc-btn-section">
              <Button
                onClick={e => handleScamSearch(e, "sms")}
                buttontext="Check for scam"
                customclass="primary-btn"
              />
            </div>
          </div>
          <div className="fab-mc-default-bg" />
        </div>
      );
    }
    else if (viewType === "website") {
      viewContent = (
        <div className="fab-main-card-container">
          <div className="fab-main-card">
            {renderTabs()}
            <div className="fab-mc-top-text">Search Website here</div>
            <TextInput
              placeholder="Enter the URL (ex.http://scammer.url.com)"
              value={formData.website}
              name="website"
              onChange={(val) => handleChange('website', val)}
            />
            <ErrorText error={errors.website} errorClass="w3-center" />
            <br />
            <div className="fab-mc-btn-section">
              <Button
                onClick={e => handleScamSearch(e, "website")}
                buttontext="Check for scam"
                customclass="primary-btn"
              />
            </div>
          </div>
          <div className="fab-mc-default-bg" />
        </div>
      );
    }
    return viewContent;
  };
  return renderView();
};

export default MainCard;
