import React, { useState, useEffect } from 'react';
import './App.scss';
import Routes from './Routes';
import Header from './components/common/Header';
import Drawer from './components/common/Drawer';
import Footer from './components/common/Footer';
import ToastMessage from './components/common/ToastMessage';
import BlogPostCard from './components/common/BlogPostCard';
import Loader from './components/common/Loader';
import { ApolloClient, InMemoryCache, gql, HttpLink } from '@apollo/client';
import LeaderBoard from './components/common/LeaderBoard';

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
      fetch,
      uri: `https://blog.scamyodha.com/graphql`,
  })
});

function DisplayBlogPosts() {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchBlogPosts = async () => {
    try {
      setIsLoading(true)
      await client.query({
        query: gql`
            query getAllPosts {
              posts(where: {categoryName: "Scam Yodha"}) {
                nodes {
                  title
                  date
                  slug
                  postId
                  link
                  status
                  excerpt(format: RENDERED)
                  featuredImage {
                    node {
                      altText
                      sourceUrl
                    }
                  }
                  author {
                    node {
                      name
                      avatar {
                        url
                      }
                      description
                    }
                  }
                  categories {
                    nodes {
                      name
                      categoryId
                      id
                      link
                    }
                  }
                  tags {
                    nodes {
                      name
                      slug
                      link
                      id
                    }
                  }
                }
              }
            }`
      }).then(resp => {
        setIsLoading(false)
        if (resp?.data?.posts?.nodes?.length > 0) {
          setPosts(resp.data.posts.nodes);
        }
      }).catch(err => {
        setIsLoading(false);
        console.log("error while fetching blog posts", err);
      });
    } catch (ex) {
      console.log("error", ex);
    }
  }
  useEffect(() => {
    fetchBlogPosts();
  }, [])

  if (isLoading) {
    return <Loader parentNode="blog-list-container" /> 
  }

  return (
    <div id="blog-list-container">
      {posts.length > 0 ?
        posts.map(item => <BlogPostCard key={item.slug} item={item} />) : <div className="fab-no-data w3-center">
          No data available
        </div>}
    </div>
  )
}


function App() {
  const [showDrawer, setShowDrawer] = useState('w3-hide');
  
  const toggleDrawer = () => {
    const isOpen = document.body.classList.contains("no-scroll");
    if (!isOpen) {
      document.body.classList.add('no-scroll');
      setShowDrawer('w3-show');
      return;
    }
    document.body.classList.remove('no-scroll');
    setShowDrawer('w3-hide');
  }

  return (
    <div className="fabcaller-app">
      <div className="app-container" id="app-container">
        <ToastMessage />
        <Header toggleDrawer={toggleDrawer} />
        <Drawer showDrawer={showDrawer} toggleDrawer={toggleDrawer} />
        <div id="app-main">
          <Routes />
        </div>
        <Footer />
      </div>
      <div className="w3-row app-right-section w3-hide-small">
          <div className='w3-col m12 l8 blog-main-container'>
            <DisplayBlogPosts />
          </div>
          <div className='w3-col l4 fb-twitter-block'>
            <div className='w3-row'>
              <LeaderBoard />
            </div>
          </div>
      </div>
    </div>
  );
}

export default App;
