import { createStore, applyMiddleware } from 'redux';
import thunkMiddleWare from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './reducer';

const middleware = [thunkMiddleWare];
const persistConfig = {
    key: 'fab-caller',
    storage,
}
const persistedReducer = persistReducer(persistConfig, rootReducer)
const store = createStore(
    persistedReducer,
    applyMiddleware(...middleware)
);

const persistor = persistStore(store);

window.gFabStore = store;

export {
    store, 
    persistor
}
