import React from "react";
import { Link } from 'react-router-dom';
import "./header.scss";
import ScamyodhaLogo from "../../../images/icons/scamyodha_app_icon.svg";
import MenuBtn from "../../../images/icons/menu_btn.svg";

const Header = ({toggleDrawer}) => {
  
  return (
    <div className="fab-header-container" id="app-header">
      <Link to={`/`} className="fab-logo fab-nav-link flex-container">
        <img src={ScamyodhaLogo} className="fab-logo-img" alt="scamyodha-logo" style={{width:"48px"}} /> 
        <span style={{fontSize:"24px",color:"white",padding:"10px",fontWeight:"bold",display:"inline-block"}}>SCAM YODHA</span>
      </Link>
      <div className="fab-menu-btn fab-cursor-pointer" onClick={()=>toggleDrawer()}>
        <img src={MenuBtn} alt="fab-menu-button"/>
      </div>
    </div>
  );
};

export default Header;
