import React, {useLayoutEffect} from 'react';
import { Route, Routes as DefaultRoutes, useLocation } from 'react-router-dom';
import AuthLayout from '../components/Auth/AuthLayout';
import Home from '../components/Home';
import Report from '../components/Report/Report';
import PageNotFound from './PageNotFound';
import ReportList from '../components/Report/ReportList';
import ReportDetail from '../components/Report/ReportDetail';
import PrivateOutlet from './PrivateOutlet';
import PrivacyPolicy from '../components/NavLinks/PrivacyPolicy'
import TermsService from '../components/NavLinks/TermsService'
import AboutUs from '../components/NavLinks/AboutUs';


export default function Routes() {
    const location = useLocation();
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);
    return (
        <DefaultRoutes>
            <Route exact path='/' element={<Home />} />
            <Route exact path='/home' element={<Home />} />
            <Route path="/auth/" element={<AuthLayout />}>
                <Route path='register' />
                <Route path='login' />
                <Route path='forgot-password' />
                <Route path='reset-password' />
                <Route path='verify-otp' />
            </Route>
            <Route path='/reports' element={<ReportList listType="main-list" />} />
            <Route path='/reports/detail/:id' element={<ReportDetail />} />
            
            <Route element={<PrivateOutlet />}>
                <Route path='/report-spam' element={<Report />} />
                <Route path='/reports/my-report' element={<ReportList listType="my-list"/>}/>
            </Route>
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="terms-of-use" element={<TermsService />} />
            <Route path="about" element={<AboutUs />} />
            <Route path="*" element={<PageNotFound />} />
        </DefaultRoutes>
    )
}
