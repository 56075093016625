import React from 'react'
import { TANDC } from '../../js/constants'

export default function TermsService() {
    return (
        <div className="fab-privacy-policy">
            <h4 className='w3-center w3-padding-16'>Terms of Service</h4>
            {TANDC.map(item => <div className='w3-padding'>
                <p className='fab-agreement-title'>{item.title}</p>
                <p className='fab-agreement-desc '>{item.content}</p>
            </div>)}
        </div>
    )
}
