import React,{useEffect} from 'react'
import { useCountDown } from './UseCountDown'


export default function CountDownTimer({ maxCount, setShowTimer, onPress,timerContainerStyle}) {
    const [countDown] = useCountDown(maxCount);

    useEffect(() => {
        if(countDown !== 0){
            return;
        }
        setShowTimer(false);
        if(onPress){
            onPress()
        }
      }, [countDown]);
    return (
        <div style={timerContainerStyle?timerContainerStyle:{ marginTop:10, marginBottom:10 }}>
            <div className="fab-timer-text">{`Resend OTP in 00:${countDown<10?'0'+countDown:countDown}`}</div>
        </div>
    )
}