import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { getReporterLeaderBoard } from '../../../js/redux/actions';
import Button from '../Button';
import Loader from '../Loader';
import './leader-board.scss';

export default function LeaderBoard() {
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [viewType, setViewType] = useState(30);
    const dispatch = useDispatch();
    const getLeaderBoardData = () => {
        setIsLoading(true);
        dispatch(getReporterLeaderBoard({viewType})).then(resp => {
            setIsLoading(false);
            const respData = resp && resp?.data;
            if (respData?.success && respData?.data) {
                setTableData(respData?.data)
            }
        }).catch(err => {
            setIsLoading(false);
            setTableData([])
            console.log('Leader board api err: ', err);
        })
    }
    useEffect(() => {
        getLeaderBoardData();
    }, [1, viewType]);

    if (isLoading) {
        return <Loader parentNode="fab-leader-board-container" />
    }
    const viewTypeMap = {
        1:'Over the past 24 hours',
        7:'Over the past 7 days',
        30:'Over the past 30 days',
        'all':'So far'
    }

    return (
        <div className="w3-responsive w3-card-2 w3-margin fab-leader-board-container w3-round" id="fab-leader-board-container">
            <div className='flex-container flex-column align-items-center justify-content-space-between w3-padding-small'>
                <div className='flex-container'>
                    <h5>Leader Board</h5>
                </div>
                <div className="fab-btn-group">
                    <div className={`fab-btn-grp-btn-text fab-cursor-pointer ${viewType === 1 && 'fab-btn-grp-active-btn fab-btn-grp-active-btn-text'}`} onClick={() => setViewType(1)}>24 Hrs</div>
                    <div className={`fab-btn-grp-btn-text fab-cursor-pointer ${viewType === 7 && 'fab-btn-grp-active-btn fab-btn-grp-active-btn-text'}`} onClick={() => setViewType(7)}>7 days</div>
                    <div className={`fab-btn-grp-btn-text fab-cursor-pointer ${viewType === 30 && 'fab-btn-grp-active-btn fab-btn-grp-active-btn-text'}`} onClick={() => setViewType(30)}> 30 days</div>
                    <div className={`fab-btn-grp-btn-text fab-cursor-pointer ${viewType === 'all' && 'fab-btn-grp-active-btn fab-btn-grp-active-btn-text'}`} onClick={() => setViewType('all')}>All Time</div>
                </div>
            </div>
            {tableData.length > 0 ? <div>
                <table className="w3-table-all">
                <tbody>
                    <tr className="fab-lb-tr">
                        <th>Name</th>
                        <th className="w3-center">Reports</th>
                    </tr>
                    {tableData.map(item => (
                        <tr key={item.reporterId} className='fab-lb-tr'>
                            <td className='lb-name'>{item.users_name}</td>
                            <td className="w3-center">{item.total}</td>
                        </tr>
                    ))}
                </tbody>
                {/* <tfoot>
                    <tr>
                        <td colSpan="2">
                            <div className='flex-container align-items-center justify-content-end'>
                                <Button
                                    onClick={() => {}}
                                    buttontext="&laquo;"
                                    customclass="secondary-btn fab-lb-btn"
                                    
                                />
                                <span className='fab-lb-btn-text'>{page}</span>
                                 hideNextBtn && <Button
                                    onClick={() => {}}
                                    buttontext="&raquo;"
                                    customclass="secondary-btn fab-lb-btn"
                                />}
                            </div>
                        </td>
                    </tr>
                </tfoot> */}
            </table></div>: <div className='w3-padding-32 w3-center' dangerouslySetInnerHTML={{__html: `<h5>${viewTypeMap[viewType]},<br/> no scam reports have been reported</h5>`}} />}
        </div>
    )
}
