import React, { useState, useEffect } from "react";
import "./scam.scss";
import { getMyReports, getSpammerListByType } from "../../js/redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import TextInput from "../common/TextInput";
import { removeNonValueFilters } from "../../js/utils";
import rightArrow from '../../images/icons/right_arrow.svg';
import { SELECT_STATUS_OPTIONS, SELECT_TYPE_OPTIONS } from "../../js/constants";
import Dropdown from "../common/Dropdown";
import moment from 'moment';
import Loader from "../common/Loader";
import searchIcon from './../../images/icons/search-icon.svg';

const ReportList = ({listType}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const globalState = useSelector((state) => state);
  const { spammerList } = globalState;
  const [reports, setReports] = useState([]);
  const [results, setResults] = useState(0);
  const [queryParams, setQueryParams] = useSearchParams({});
  const existingParams = Object.fromEntries([...queryParams]);
  const [isLoading, setIsLoading] = useState(false);
  let viewContent = null;

  useEffect(() => {
    const type = queryParams.get("type") || 'number';
    const reportType = queryParams.get("reportType") || 'all';
    const status = queryParams.get("status") || 'ACTIVE';
    let params = { type, status }
    if(listType === 'main-list'){
      params = {
        ...params,
        reportType
      }
    }
    setQueryParams(params);
  }, []);

  useEffect(() => {
    getReports();
  }, [queryParams]);

  const getReports = () => {
    setIsLoading(true);
    setReports([]);
    setResults(0);
    if (listType === 'my-list') {
      dispatch(getMyReports({ 
        type: existingParams?.type, 
        status: existingParams?.status,
        search: existingParams?.search
      })).then((resp) => {
        setIsLoading(false);
        const respData = resp && resp.data;
        if (respData?.success && respData?.data && respData.data.length > 0) {
          let data = respData.data
          data = data.map(ele => ({
            ...ele,
            lastReportedAt: moment(ele.lastReportedAt).format('LLL')
          }))
          setResults(respData?.total);
          setReports(data);
        }
      });
    }
    else {
      dispatch(getSpammerListByType({ 
        type:existingParams?.type, 
        reportType:existingParams?.reportType,
        status: existingParams?.status,
        search: existingParams?.search
      })).then((resp) => {
        setIsLoading(false);
        const respData = resp && resp.data;
        if (respData?.success && respData?.data && respData.data.length > 0) {
          let data = respData.data
          data = data.map(ele => ({
            ...ele,
            lastReportedAt: moment(ele.lastReportedAt).format('LLL')
          }))
          setResults(respData?.total);
          setReports(data);
        }
      });
    }

  }

  const updateQueryParams = params => {
    const nParams = removeNonValueFilters(Object.assign({}, existingParams, params));
    setQueryParams(nParams);
  }

  if (spammerList?.isFetching || !reports || isLoading) {
    viewContent = <Loader parentNode="fab-report-list-container" /> ;
  } else if (reports.length > 0) {
    viewContent = (
      <>
        {reports
          .filter(item => item.type === existingParams?.type)
          .map((report) => (
            <div key={`report-${report.id}`} className="fab-report-list-card fab-cursor-pointer" onClick={() => navigate(`/reports/detail/${report.id}`, { state: { report } })}>
              <div className="fab-report-list-card-inner">
                <div className="w3-left">
                  <div className="fab-report-list-card-title">{report.encryptedNumber}</div>
                  <div className="fab-report-list-card-sub-title">Last reported at<span className="fab-report-list-card-sub-desc">{report.lastReportedAt}</span></div>
                  <div className="fab-report-list-card-sub-title">Report count<span className="fab-report-list-card-sub-desc">{report.reportCount}</span></div>
                  <div className="fab-report-list-card-sub-title flex-container">
                      <div className={`fab-report-list-card-status ${report.status.toLowerCase() }`}>
                        {report.status}
                      </div>
                  </div>
                </div>
                <div className={`fab-report-list-card-btn-section`}>
                  <span className="fab-cursor-pointer w3-right" >
                    <img src={rightArrow} alt="view-button" />
                  </span>
                </div>
              </div>
            </div>
          ))}
      </>);
  } else {
    const dataType = existingParams?.type === 'number'?'Phone': existingParams?.type
    viewContent = (
      <div className="fab-no-data w3-center">
        Scam reports not found on {dataType} in status - {existingParams?.status} 
      </div>
    );
  }

  const handleReportType = reportType => { updateQueryParams({reportType}) };
  const handleSearch = search => { updateQueryParams({ search }) }

  return (
    <div className="fab-report-list-container" id="fab-report-list-container">
      <div className="flex flex-col w3-margin-bottom">
        <div className="fab-spam-top-section">
          {existingParams?.reportType === 'all' && <div className="fab-page-heading">All Spammers</div>}
          {listType === 'my-list' && <div className="fab-page-heading">My Reports</div>}
          {existingParams?.reportType === 'recent' && <div className="fab-page-heading">Recently reported</div>}
          {existingParams?.reportType === 'most' && <div className="fab-page-heading">Most reported</div>}
          <Dropdown
            customCls="fab-rl-drop-down"
            placeholder="Select Type"
            options={SELECT_TYPE_OPTIONS}
            selectedValue={SELECT_TYPE_OPTIONS.find(sItem=> sItem.key === existingParams?.type)}
            onChange={val => updateQueryParams({ type: val.key })}
            height={30}
          />
        </div>
        <div className="flex-container align-items-center justify-content-space-between">
          <div className="w3-block fab-search-container">
            <TextInput
              inputclass="fab-search-box"
              placeholder="Search Report"
              value={queryParams.get("search") || ''}
              name="searchTerm"
              onChange={(val) => handleSearch(val)}
            />
            <span className="fab-search-icon fab-cursor-pointer">
              <img className="fab-dropdown-icon" src={searchIcon} alt="search-icon" />
            </span>
          </div>
          <Dropdown
            placeholder="Status"
            options={SELECT_STATUS_OPTIONS}
            selectedValue={SELECT_STATUS_OPTIONS.find(sItem=> sItem.key === existingParams?.status)}
            onChange={val => updateQueryParams({ status: val.key })}
            height={30}
            customCls="w3-margin-left fab-rl-drop-down"
          />
        </div>
      </div>

      {existingParams?.reportType &&
        <div className="fab-btn-group">
          <div className={`fab-btn-grp-btn-text fab-cursor-pointer ${existingParams?.reportType === 'all' && 'fab-btn-grp-active-btn fab-btn-grp-active-btn-text'}`} onClick={() => handleReportType('all')}>All</div>
          <div className={`fab-btn-grp-btn-text fab-cursor-pointer ${existingParams?.reportType === 'recent' && 'fab-btn-grp-active-btn fab-btn-grp-active-btn-text'}`} onClick={() => handleReportType('recent')}>Recent</div>
          <div className={`fab-btn-grp-btn-text fab-cursor-pointer ${existingParams?.reportType === 'most' && 'fab-btn-grp-active-btn fab-btn-grp-active-btn-text'}`} onClick={() => handleReportType('most')}>Most Reported</div>
        </div>
      }
      <div className="flex-container">{`${results} results`}</div>
      <div style={{ minHeight: "65vh", }}>{viewContent}</div>
    </div>
  );
};

export default ReportList;
