import React from 'react';
import './phone-number-input.scss';
import flag from '../../../images/icons/IN_flag.svg';

export default function PhoneNumberInput(props) {
    const {inputclass, value } = props;
    return (
        <div className="fab-phone-input-container">
            <div className="fab-phone-code-container">
                <img src={flag} alt="flag" className="fab-country-flag" />
                <div className="fab-phone-code fab-phone-input-active">
                    +91
                </div>
            </div>
            <input
                type='tel'
                className={`fab-phone-input ${!value?'fab-phone-input-placeholder':'fab-phone-input-active'} ${inputclass || ''}`}
                value={value}
                {...props}
            />
        </div>
    )
}
