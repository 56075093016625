import React from 'react'
import { Link } from 'react-router-dom';

export default function PageNotFound() {
    return (
        <div className="w3-padding-64 w3-center fab-not-found">
            <p>404 Page not found</p>
            <span>Goto <Link to='/' className="fab-nav-link">Home</Link></span>
        </div>
    )
}
