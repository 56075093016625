import React, { useState } from 'react';
import DownCaretIcon from '../../../images/icons/dropdown_down.svg';
import UpCaretIcon from '../../../images/icons/dropdown_up.svg';
import './accordion.scss';

export default function Accordion(props) {
    const { title, content, index, selectedIndex, setSelectedIndex } = props;
    const [isActive, setIsActive] = useState(false);
    const condition = (index === selectedIndex && isActive);
    const handleOnclick = (idx) =>{
        setSelectedIndex(idx)
        setIsActive(!isActive)
    }
    return (
        <div className="fab-accordion-item">
            <div id={index} className={`fab-accordion-title ${condition && 'fab-active-accordion'} fab-cursor-pointer`} onClick={() =>handleOnclick(index)}>
                <div>{title}</div>
                <img src={ condition ? UpCaretIcon : DownCaretIcon} alt="caret-icon" style={{ marginLeft: 'auto' }} />
            </div>
            {condition && <div className="fab-accordion-content" dangerouslySetInnerHTML={{ __html: content }}/>}
        </div>
    );
}
