import React from 'react'
import './progress-bar.scss';

export default function ProgressBar({ percent }) {
    return (
        <div className="fab-progress-bar">
            <div className="fab-progress" style={{ width: `${percent}%` }}></div>
        </div>
    )
}
