import { parsePhoneNumberFromString } from "libphonenumber-js";
import { BASE_URL } from "./constants";

export const acceptOnlyNumbersInput = value => {
    const pattern = /^[0-9\b]+$/;
    return pattern.test(value);
}

export const isValidPhoneNumber = phoneNo => {
    if (!phoneNo) return;
    const countryCode = 'IN';
    const parsedNo = parsePhoneNumberFromString(phoneNo, countryCode).nationalNumber;
    return parsePhoneNumberFromString(parsedNo, countryCode).isValid();
}

export const getParsedPhoneNumber = phoneNo => {
    if(!phoneNo) return;
    const countryCode = 'IN';
    return parsePhoneNumberFromString(phoneNo, countryCode).nationalNumber;
}

export const clearFormFieldErrors = (key, errors) => {
    const errorField = Object.assign({}, errors);
    if (key && errorField[key]) {
        errorField[key] = null;
    }
    return errorField;
}

export const getProofUrl = (filePath) => {
    let url = "";
    if (filePath) {
        url = `${BASE_URL}/api/v1/scam-report/file?file=${filePath}`;
    }
    return url;
}

export const isValidUpiId = upiId => {
    if (!upiId) return;
    return (/^[\w.-]+@[\w.-]+$/).test(upiId);
}

export const removeNonValueFilters = (filterValues={})=>{
    const filters = {};
    Object.keys(filterValues).forEach(key => {
      if (filterValues[key] !== '') {
        Object.assign(filters, { [key]: filterValues[key] });
      }
    });
    return filters;
}

export const maskString = (str, num = 4, mask = '*') => `${str}`.slice(-num).padStart(`${str}`.length, mask);