import React, { useEffect, useState, useRef } from 'react';

function useInterval(callback, duration) {
    const callbackRef = useRef();

    useEffect(() => {
        callbackRef.current = callback;
    });

    useEffect(() => {
        const interval = setInterval(() => {
            callbackRef.current();
        }, duration);
        return () => clearInterval(interval);
    }, [duration]);
}

export default function Counter({ target }) {
    const [count, setCount] = useState(0);
    useInterval(() => {
        let add = 10;
        if(target > 100 && target < 1000){
            add = 50;
        }else if(target > 1000 && target < 3000){
            add = 100;
        }
        
        if (count < target) {
            setCount(count + add);
        } else {
            setCount(target);
        }
    }, 50);
    return <>{count}</>;
}