import React from 'react';
import Layout from '../Layout';
import MainCard from '../common/MainCard';

export default function Home() {
    return (
        <Layout>
            <MainCard />
        </Layout>
    )
}