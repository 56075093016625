import axios from 'axios';
import { BASE_URL } from '../constants';
import api from './api';

const requestMap = api;
export const axiosApiCall = axios.create({
    baseURL: BASE_URL,
    // timeout: 2500
});

const headers = {
    'App-Check': 'web' 
}

export const actions = {
    FETCH_REQUEST: 'FETCH_REQUEST',
    FETCH_REQUEST_SUCCESS: 'FETCH_REQUEST_SUCCESS',
    FETCH_REQUEST_ERROR: 'FETCH_REQUEST_ERROR',
    SET_DATA: 'SET_DATA',
    SET_TOAST_MESSAGE: 'SET_TOAST_MESSAGE',
    CLEAR_TOAST_MESSAGE: 'CLEAR_TOAST_MESSAGE',
    USER_LOGOUT: 'USER_LOGOUT',
};


function setToastMsgData(value) {
    return {
        type: actions.SET_TOAST_MESSAGE,
        key: 'toastMessage',
        value
    };
}

function clearToastMsgData() {
    return {
        type: actions.CLEAR_TOAST_MESSAGE,
        key: 'toastMessage'
    };
}

export function setToastMessage(data) {
    return (dispatch) => {
        dispatch(setToastMsgData(data));
        dispatch(clearToastMessage());
    };
}

export function clearToastMessage() {
    return (dispatch) => {
        dispatch(clearToastMsgData())
    };
}

export const onLogoutClearStoreData = () => {
    return {
        type: actions.USER_LOGOUT
    }
}


export const setStoreData = (key, value) => {
    return {
        type: actions.SET_DATA,
        key,
        value
    };
};

export const fetchDataRequest = (key) => {
    return {
        type: actions.FETCH_REQUEST,
        key
    };
};

export const fetchDataRequestError = (key, error) => {
    return {
        type: actions.FETCH_REQUEST_ERROR,
        key,
        error
    };
};

export const fetchResponseSuccess = (key, data) => {
    return {
        type: actions.FETCH_REQUEST_SUCCESS,
        key,
        data
    };
};

export const makeRequest = (key, params = {}, path = [], pathParams = {}) => {
    return (dispatch) => {
        const request = Object.assign({}, requestMap[key]);
        if (path.length > 0) {
            request.path += '/' + path.join('/');
        }

        if (request.method === undefined || request.method === 'GET') {
            request.method = 'GET';
            request.params = params;
        }

        if (pathParams) {
            Object.keys(pathParams).forEach((param) => {
                request.path = request.path.replace(`{${param}}`, pathParams[param])
            })
        }

        const config = {
            url: request.path,
            params,
            data: params,
            method: request.method.toLowerCase(),
            headers: {
                ...headers,
            }
        }

        if (localStorage.getItem('fab_access_token')) {
            config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('fab_access_token');
        }

        return axiosApiCall(config)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });
    };
};

export const fireRequest = (key, params = {}, path = [], pathParams = {}) => {
    return (dispatch) => {
        const request = Object.assign({}, requestMap[key]);
        if (path.length > 0) {
            request.path += '/' + path.join('/');
        }

        if (request.method === undefined || request.method === 'GET') {
            request.method = 'GET';
            request.params = params;
        }

        if (pathParams) {
            Object.keys(pathParams).forEach((param) => {
                request.path = request.path.replace(`{${param}}`, pathParams[param])
            })
        }

        const config = {
            url: request.path,
            params: request.params,
            data: params,
            method: request.method.toLowerCase(),
            headers: {
                ...headers,
            }
        }

        if (localStorage.getItem('fab_access_token')) {
            config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('fab_access_token');
        }

        dispatch(fetchDataRequest(key));
        return axiosApiCall(config)
            .then((response) => {
                dispatch(fetchResponseSuccess(key, response.data));
                return response;
            })
            .catch((error) => {
                dispatch(fetchDataRequestError(key, error));
                return error.response;
            });
    };
};

//Auth actions

export const getIsTokenValid = (token) => {
    return makeRequest('isTokenValid',{token});
};

export const getCurrentUser = () => {
    return fireRequest('currentUser');
};

export const postRegister = (form) => {
    return fireRequest('register', form);
};

export const postLogin = (form) => {
    return fireRequest('login', form);
};

export const postForgotPassword = (form) => {
    return fireRequest('forgotPassword', form);
};

export const postResetPassword = (form) => {
    return fireRequest('resetPassword', form);
};

export const postVerifyUser = (form) => {
    return fireRequest('verifyUser', form);
};

export const postResendOTP = (form) => {
    return fireRequest('resendOtp', form);
};

export const logout = () => {
    return fireRequest('logout', {});
}

export const getUsersCount = () => {
    return fireRequest('usersCount');
};

// Scam Actions

export const checkScamReport = (form) => {
    return fireRequest('checkScamReport', form);
};

export const checkScamReportSendOTP = (form) => {
    return fireRequest('checkScamReportSendOTP', form);
};

export const reportScammer = (form) => {
    return fireRequest('reportScammer', form);
};

export const verifyOtp = (form) => {
    return fireRequest('verifyOtp', form);
};

export const getMyReports = (data) => {
    return fireRequest('myReports', data);
};

export const getSpammerListByType = (data) => {
    return fireRequest('spammerListByType', data);
};

export const getSpammerDetail = (id) => {
    return fireRequest('spammerDetail', { id });
};

export const scamVerification = (form) => {
    return fireRequest('scamVerification', form);
};

export const postScamRejection = (form) => {
    return fireRequest('scamRejection', form);
};

export const seeUploadedFile = (file) => {
    return fireRequest('seeUploadedFile', { file });
};

export const getReportsCount = () => {
    return fireRequest('reportsCount');
};

export const getScamStats = () => {
    return fireRequest('scamStats');
}
export const getReporterLeaderBoard = (params) => {
    return fireRequest('reporterLeaderBoard',params);
};
