import React, { useRef, useState } from "react";
import SingleInput from "./SingleInput";
import './box-input.scss';


export default function BoxInput(props) {
    const { totalBox, onChange, disabled = false, secureEntry = true, showBoxSeparator = false, boxSeparator = '-', inputBoxClass='' } = props;
    const [arrInputs] = useState(new Array(totalBox).fill(""));
    const inputRef = useRef([]);
    const [boxInputValue, setInputBoxValue] = useState(new Array(totalBox).fill(""));

    const handleOnChange = (e, idx) => {
        boxInputValue[idx] = e.target.value;
        setInputBoxValue(boxInputValue);
        if (e.target.value.length > 0 && idx < totalBox - 1)
            inputRef.current[idx + 1].focus();
        onChange(boxInputValue.join(""));

    };

    const handleBackSpace = (e, idx) => {
        if (idx > 0) {
            inputRef.current[idx - 1].focus();
        }
    };

    return (
        <div className="box-input-container">
            {arrInputs.map((elem, idx) => {
                return (
                    <SingleInput
                        key={idx}
                        disabled={disabled}
                        ref={(elem) => { inputRef.current[idx] = elem; }}
                        onChange={(e) => handleOnChange(e, idx)}
                        onBackSpace={(e) => { handleBackSpace(e, idx); }}
                        isLastBox={idx === (totalBox - 1)}
                        secureEntry={secureEntry}
                        showBoxSeparator={showBoxSeparator}
                        boxSeparator={boxSeparator}
                        inputBoxClass={inputBoxClass}
                    />
                );
            })}
        </div>
    );
};