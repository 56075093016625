import React from 'react';
import './button.scss';

export default function Button(props) {
    const { onClick, customclass, buttontext } = props;
    return (
        <button
            {...props}
            className={`fab-btn ${customclass || ''}`}
            onClick={onClick}
        >
            {buttontext}
        </button>
    )
}
