import React from 'react';
import './blog-post.scss';
import moment from 'moment';

export default function BlogPostCard({ item }) {
    if (!item) {
        return null
    }

    return (
        <div className='w3-container w3-card w3-white w3-round w3-margin w3-padding'>
            <a className="fab-nav-link" href={item.link.replace('scamyodha.blog.ceegees.in','blog.scamyodha.com')} target="_blank" rel="noreferrer">
                <div className="w3-row w3-margin-bottom">
                    <div className='w3-col m8'>
                        <h5>{item?.title}</h5>
                        <div className='blog-description w3-margin-right' dangerouslySetInnerHTML={{ __html: item?.excerpt }} />
                    </div>
                    <div className='w3-col m4'>
                        <div className='featured-img-wrapper'>
                            <img src={item?.featuredImage?.node?.sourceUrl} alt={item.featuredImage?.node?.altText} className="w3-image w3-round" loading="lazy" />
                        </div>
                    </div>
                </div>
            </a>
            <div className='flex-container align-items-center justify-content-start'>
                <img src={item?.author?.node?.avatar?.url} alt="Avatar" className="w3-image w3-circle w3-margin-right" style={{ width: 20 }} loading="lazy" />
                <h6 className='w3-margin-right'>{item?.author?.node?.name}</h6>
                <span className="w3-opacity">{moment(item.date).format('ll')}</span>
                <div className="w3-tag w3-light-grey w3-round w3-padding-tiny" style={{ marginLeft: 'auto' }}>
                    {item?.categories?.nodes[0].name}
                </div>
            </div>
        </div>
    )
}
