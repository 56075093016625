import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ToastMessage = () => {
    const globalState = useSelector(state => state);
    const toastMessage  = globalState?.toastMessage;
    const { status, message } = toastMessage;

    useEffect(() => {
        if (!status || !message) return;

        if (status === 'success') {
            toast.success(message);
        } else if (status === 'error') {
            toast.error(message);
        } else if(status === "info") {
            toast.info(message);
        } else {
            toast.info(message);
        }
    }, [status, message]);

    return (
        <ToastContainer
            style={{ width: "390px" }}
            position="top-left"
            autoClose={3000}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnHover={false}
        />
    );
}
export default ToastMessage;
