import React from "react";

export default function AuthCard({ children }) {
    return (
        <div className="fab-auth-card-container">
            <div className="fab-auth-card">
                {children}
            </div>
            <div className="fab-ac-default-bg-container">
                <div className="fab-ac-default-bg" />
            </div>
        </div>
    )
}
