import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import './layout.scss';
import { FAQS, HOW_IT_WORKS, SOCIAL_LINKS } from '../../js/constants';
import Accordion from '../common/Accordion';
// import Carousel from '../common/Carousel';
import {   getScamStats, getReporterLeaderBoard } from '../../js/redux/actions';
import Counter from './Counter';
import LeaderBoard from '../common/LeaderBoard';

export default function Layout({ children }) {
    const dispatch = useDispatch();
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [stats, setStats] = useState({
        reports: 0,
        users: 0,
        msgHeader: 'Take charge of your contact! Before giving access, verify to see whether it is SPAM.',
        msgFooter: 'Finally! No more annoying contact seeking apps',
        msgFooter2: 'Always know who’s calling'
    });

    const getCounts = () => {
        dispatch(getScamStats()).then(resp => {
            const respData = resp && resp?.data;
            if (respData?.success) {
                setStats(respData);
            }
        });
    }
    useEffect(() => {
        getCounts();
        return () => {
            setSelectedIndex(-1);
        }
    }, [])

    return (
        <div className="fab-layout-main">
            <div className="fab-layout-top-bg">
                <div className="fab-layout-base-text">
                    {stats.msgHeader}
                </div>
                {children}
                <div className="fab-blocked-spammers-section">
                    <div className="fab-blocked-spammers-text">
                        BLOCKED SPAMMERS
                    </div>
                    <div className="fab-blocked-spammers-count">
                        <Counter target={stats.reports} />
                    </div>
                </div>
                <div className="fab-trusted-by-section">
                    <div className="fab-trusted-by-text">
                        TRUSTED BY
                    </div>
                    <div className="fab-trusted-by-count">
                        <Counter target={stats.users} />
                    </div>
                </div>
                <div className="fab-download-app-section">
                    <div className="fab-download-app-info-text">
                        {stats.msgFooter} <br />
                        <span className="fab-download-app-info-inner-text">
                            {stats.msgFooter2}
                        </span>
                    </div>
                    <a className="fab-btn primary-btn fab-nav-link" href={SOCIAL_LINKS.playStore} target="_blank" rel="noreferrer">
                        Download Android App
                    </a>
                </div>
                <div className="fab-working-info-section">
                    <div className="fab-section-title">How it works?</div>
                    {HOW_IT_WORKS.map(item => <div key={item.title}>
                        <div className="fab-working-info-title">{item.title}</div>
                        <div className="fab-working-info-description">{item.content}</div>
                    </div>)}
                </div>
                {/* <div className="fab-carousel-section">
                    <div className="fab-section-title">What our users think!</div>
                    <Carousel />
                </div> */}
                <div className='w3-hide-large w3-hide-medium w3-margin-bottom'>
                    <LeaderBoard />
                </div>
                <div className="fab-faqs">
                    <div className="fab-section-title">FAQ</div>
                    <div className="fab-accordion-container">
                        {FAQS.map((item, idx) => (<Accordion key={item.title} index={idx} title={item.title} content={item.content} selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} />))}
                    </div>
                </div>
            </div>
        </div>
    )
}
