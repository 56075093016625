import React from 'react'
import './about.scss';
import { CGS_TEXT, HOLA, SOCIAL_LINKS, TEAMS } from '../../js/constants';
import MemberImage1 from '../../images/members/member_1.svg';
import MemberImage2 from '../../images/members/member_2.svg';
import MemberImage3 from '../../images/members/member_3.svg';
import MemberImage4 from '../../images/members/member_4.svg';
import twitterLogo from '../../images/icons/twitter_icon.svg';
import fbLogo from '../../images/icons/facebook_icon.svg';
import linkedInLogo from '../../images/icons/linkedin_icon.svg';
import discordLogo from '../../images/icons/discord_icon.svg';
import cgsLogo from '../../images/icons/cgs_logo.svg';

const teamMemberPicMap = {
    'member1': MemberImage1,
    'member2': MemberImage2,
    'member3': MemberImage3,
    'member4': MemberImage4,
}

const TeamMemberCard = ({ data }) => {
    return (
        <div className="fab-team-card">
            <img src={teamMemberPicMap[`member${data.id}`]} alt="team-pic" />
            <div className="fab-team-member-name">{data.name}</div>
            <div className="fab-team-member-designattion">{data.designation}</div>
        </div>
    )
}
export default function AboutUs() {
    return (
        <div className='fab-about-main-container w3-padding'>
            <div className='w3-center'>
                <h4 className='w3-padding-16'>About</h4>
                <h1 className='fab-hola-title'>Hola 👋</h1>
                <p className='fab-hola-desc'>{HOLA}</p>
            </div>
            <div className="fab-teams-section">
                <div className="fab-section-title">The Team</div>
                <div className="fab-teams-row">
                    {TEAMS.map(item => <TeamMemberCard key={`member-${item.id}`} data={item} />)}
                </div>
            </div>
            <div className='fab-company-section'>
                <p className='fab-section-title'>Company</p>
                <div className='fab-cgs-logo-section'>
                    <img src={cgsLogo} alt="cgs-logo" />
                </div>
                <p className='fab-hola-desc w3-margin-bottom'>{CGS_TEXT}</p>
            </div>
            <a className='fab-btn primary-btn fab-about-discord-btn fab-nav-link' href={SOCIAL_LINKS.discordChannel} target="_blank" rel="noreferrer">
                <span>Join Discord</span>
                <img src={discordLogo} alt="discord-logo" />
            </a>
            <div className="fab-about-social-container">
                <a className="fab-about-social-btn fab-nav-link"  href={SOCIAL_LINKS.twitter} target="_blank" rel="noreferrer">
                    <img src={twitterLogo} alt="twitter-logo" />
                </a>
                <a className="fab-about-social-btn fab-nav-link" href={SOCIAL_LINKS.facebook} target="_blank" rel="noreferrer">
                    <img src={fbLogo} alt="facebook-logo" />
                </a>
                <a className="fab-about-social-btn fab-nav-link" href={SOCIAL_LINKS.linkedIn} target="_blank" rel='noreferrer'>
                    <img src={linkedInLogo} alt="linkedin-logo" />
                </a>
            </div>
        </div>
    )
}
